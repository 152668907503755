import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import ContentLayout from "Components/ContentLayout";
import OrganizationBasicSettingsContainer from "Containers/OrganizationBasicSettingsContainer";

const OrganizationSettingsLayout = styled.div`
  background-color: transparent;
`;

class OrganizationSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      edit: false
    };
  }

  render() {
    return (
      <ContentLayout>
        <OrganizationSettingsLayout className="settings-content">
          {this.props.children ? (
            this.props.children
          ) : (
            <OrganizationBasicSettingsContainer {...this.props.params} />
          )}
        </OrganizationSettingsLayout>
      </ContentLayout>
    );
  }
}

OrganizationSettings.propTypes = {
  params: PropTypes.shape({
    projectId: PropTypes.string,
    organizationId: PropTypes.string
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }),
  router: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  children: PropTypes.node,
  layout: PropTypes.string
};

export default OrganizationSettings;

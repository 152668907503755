import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

import Label from "Components/fields/Label";
import Button from "Components/Button";
import Description from "Components/fields/Description";
import DeleteIcon from "Icons/DeleteIcon";
import UserIcon from "Icons/UserIcon";

const FieldWrapper = styled.div`
  margin-bottom: 16px;
`;

const ImageUploadWrapper = styled.div`
  .label-button-button {
    background-color: ${props => props.theme.buttonBg};
    color: ${props => props.theme.buttonText};
    border: none;
    border-radius: 4px;
    padding: 0px 32px 2px;
    height: 40px;
    line-height: 38px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 24px;
    &:hover {
      background-color: ${props => props.theme.buttonHover};
    }
  }
  .upload-area {
    position: relative;
    input[type="file"] {
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
    }
    &:focus-within {
      .label-button-button {
        border: 1px solid ${props => props.theme.buttonHover};
        margin: 0 -1px 24px;
        line-height: 40px;
        box-shadow: 0 0 2px ${props => props.theme.buttonHover};
        outline: none;
      }
    }
  }
  .file-description {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .delete {
    padding: 0;
    line-height: 24px;
    height: 24px;
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
      margin: 0;
      background: rgba(0, 0, 0, 0.1);
    }
  }
`;

const PreviewWrapper = styled.div`
  height: auto;
  width: 100%;
  overflow: hidden;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 32px 32px 0;
  box-shadow: 0 10px 20px rgba(75, 97, 128, 0.08);
  border-radius: 2px;
  img {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 185px;
  }
  &.empty {
    height: 185px;
    svg {
      width: 80px;
      height: auto;
    }
  }
  .error-alert {
    border: none;
    padding: 4px 0 0;
    .icon {
      display: none;
    }
    .message {
      margin-left: 0;
      font-size: 13px;
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
`;

const InputWrapper = styled.div``;

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      imagePreviewUrl: ""
    };
    this._handleImageChange = this._handleImageChange.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
  }

  _handleSubmit(e) {
    e.preventDefault();
    // TODO: do something with -> this.state.file
  }

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    };

    reader.readAsDataURL(file);
  }

  deleteImage() {
    this.setState({
      file: "",
      imagePreviewUrl: ""
    });
  }

  render() {
    let { imagePreviewUrl } = this.state;
    let imagePreview = <UserIcon color="#f0f2f5" />;
    if (imagePreviewUrl) {
      imagePreview = <img alt="preview" src={imagePreviewUrl} />;
    }

    const { label, intl, className, labelButton = false } = this.props;

    return (
      <FieldWrapper
        className={`image-upload-field${className ? " " + className : ""}${
          labelButton ? " label-button" : ""
        }`}
      >
        {label && !labelButton && <Label htmlFor="image-upload">{label}</Label>}
        <ImageUploadWrapper>
          <PreviewWrapper className={`${imagePreviewUrl ? "" : "empty"}`}>
            {imagePreview}
          </PreviewWrapper>
          <InputWrapper className="upload-area">
            {labelButton && (
              <Label
                id="image-upload-button"
                className="label-button-button"
                htmlFor="image-upload"
              >
                {label}
              </Label>
            )}
            <input
              type="file"
              accept="image/*"
              id="image-upload"
              name="image-upload"
              onChange={this._handleImageChange}
            />
            <Description className="file-description">
              Max. file size 4mb{" "}
              <Button className="delete" onClick={this.deleteImage}>
                <DeleteIcon />
              </Button>
            </Description>
            {!labelButton && (
              <Button type="button" onClick={this._handleSubmit}>
                {intl.formatMessage({ id: "upload_image" })}
              </Button>
            )}
          </InputWrapper>
        </ImageUploadWrapper>
      </FieldWrapper>
    );
  }
}

ImageUpload.propTypes = {
  fileTypes: PropTypes.array,
  label: PropTypes.string,
  intl: PropTypes.object,
  className: PropTypes.string,
  labelButton: PropTypes.bool
};

export default injectIntl(ImageUpload);

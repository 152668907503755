import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl } from "react-intl";

import client from "Libs/platform";
import OrganizationBasicFormSettings from "Components/OrganizationBasicFormSettings";
import Heading2 from "Components/styleguide/Heading2";

const OrganizationBasicsWrapper = styled.div`
  .setting-line {
    label {
      margin: 0;
    }
  }
`;

class OrganizationBasicSettingsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editAccount: false,
      editPicture: false,
      editNewsletter: false,
      editInformation: false,
      userProfile: {}
    };

    this.onSave = this.onSave.bind(this);
  }

  async componentDidMount() {
    const uuid = this.props.me.get("id");
    const userProfile = await client.getUserProfile(uuid);
    this.setState({ userProfile });
  }

  closeForm(value) {
    this.setState({
      [value]: false
    });
  }

  async onSave(data) {
    Object.keys(data).forEach(key => data[key] == null && delete data[key]);
    const uuid = this.props.me.get("id");
    const responseFromUpdate = await client.updateUserProfile(uuid, data);
    // There is a bug in the API that returns and an from PATCH on /profile/uuid.
    const updatedUserProfile = responseFromUpdate[0];
    this.setState({ updatedUserProfile });
  }

  render() {
    const { organizationTitle, intl } = this.props;
    const { userProfile } = this.state;

    return (
      <OrganizationBasicsWrapper>
        <Heading2 id="settings-heading">
          {intl.formatMessage({ id: "account_settings" })}
        </Heading2>
        <OrganizationBasicFormSettings
          title={organizationTitle}
          onSave={this.onSave}
          className="organization-basic-form"
          onImagesChange={this.onImagesChange}
          {...userProfile}
        />
      </OrganizationBasicsWrapper>
    );
  }
}

OrganizationBasicSettingsContainer.propTypes = {
  projectId: PropTypes.string,
  organizationId: PropTypes.string,
  organizationTitle: PropTypes.string,
  updateOrganization: PropTypes.func,
  intl: PropTypes.object,
  me: PropTypes.object
};

const mapStateToProps = (state, props) => ({
  organizationTitle: state.organization.getIn(
    ["data", props.organizationId],
    {}
  ).title,
  me: state.app.get("me", new Map())
});

export default connect(mapStateToProps)(
  injectIntl(OrganizationBasicSettingsContainer)
);

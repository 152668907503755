import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import styled from "styled-components";
import { Link } from "react-router";

import Button from "./Button";
import InputField from "./fields/InputField";
import OrganizationBasicFormLayout from "Components/OrganizationBasicFormLayout";
import ImageUpload from "./fields/ImageUpload";
import ListGroup from "Components/ListGroup";
import Heading3 from "Components/styleguide/Heading3";
import Dropdown from "./Dropdown";
import CheckboxField from "Components/fields/CheckboxField";

const ColumnWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  .account-settings-wrapper {
    padding: 32px;
  }
  .input-wrapper {
    margin-bottom: 16px;
  }
  .request-password {
    margin-top: 16px;
    font-size: 14px;
    text-decoration: none;
    color: ${props => props.theme.links};
    display: inline-block;
  }
  hr {
    margin: 32px 0 24px;
  }
  .image-upload-field {
    margin-right: 32px;
  }
  h3 {
    font-weight: normal;
    font-size: 16px;
    margin-bottom: 24px;
  }
`;

const Column = styled.div`
  width: calc(50% - 16px);
  margin-right: 32px;
  .select-box,
  .select-box__control {
    height: 40px;
  }
  &:last-child {
    margin-right: 0;
  }
`;

class OrganizationBasicFormSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display_name: props.display_name,
      username: props.username,
      current_password: "",
      email: props.email,
      website_url: props.website_url,
      company_role: props.company_role,
      company_type: props.company_type,
      marketing: props.newsletter,
      isChanged: false
    };

    this.onFieldChange = this.onFieldChange.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onToggleChange = this.onToggleChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      display_name: nextProps.display_name,
      username: nextProps.username,
      email: nextProps.email,
      website_url: nextProps.website_url,
      company_role: nextProps.company_role,
      company_type: nextProps.company_type,
      marketing: nextProps.marketing
    });
  }

  onFieldChange(value, stateName) {
    this.setState({
      [stateName]: value,
      isChanged: true
    });
  }

  onSelectChange(value, stateName) {
    this.setState({
      [stateName]: value,
      isChanged: true
    });
  }

  onToggleChange(value, stateName) {
    this.setState({
      [stateName]: value.target.checked,
      isChanged: true
    });
  }

  onImageChange(images) {
    this.setState({
      picture: images[0],
      isChanged: true
    });
  }

  onCancel() {
    this.setState({
      display_name: this.props.display_name,
      username: this.props.username,
      currentPassword: "",
      password: "",
      confirmPassword: "",
      email: this.props.email,
      website_url: this.props.website_url,
      company_role: this.props.company_role,
      company_type: this.props.company_type,
      marketing: this.props.newsletter,
      isChanged: false
    });
  }
  render() {
    const { onSave, intl } = this.props;
    // @todo: get email working....
    // TODO: Make sure all fields work (some do some don't).
    return (
      <OrganizationBasicFormLayout>
        <ColumnWrapper>
          <ImageUpload
            label="Choose file"
            onChange={this.onImageChange}
            className="col-2"
            labelButton={true}
          />
          <ListGroup className="account-settings-wrapper">
            <ColumnWrapper>
              <Column className="user-details">
                <Heading3>
                  {intl.formatMessage({ id: "user_details" })}
                </Heading3>
                <InputField
                  onChange={e =>
                    this.onFieldChange(e.target.value, "display_name")
                  }
                  id="organization-display-name"
                  label={intl.formatMessage({ id: "display_name" })}
                  value={this.state.display_name}
                />
                <InputField
                  onChange={e => this.onFieldChange(e.target.value, "username")}
                  id="organization-name"
                  label={intl.formatMessage({ id: "username" })}
                  value={this.state.username}
                />
                <InputField
                  onChange={e => this.onFieldChange(e.target.value, "email")}
                  id="organization-email"
                  label={intl.formatMessage({ id: "email" })}
                  value={this.state.email}
                />
                <InputField
                  onChange={e => this.onFieldChange(e, "current_password")}
                  id="organization-current-pw"
                  label={intl.formatMessage({ id: "current_password" })}
                  value={this.state.current_password}
                  type="password"
                />
                <Link to="#" className="request-password">
                  Request new password
                </Link>
              </Column>
              <Column className="company-details">
                <Heading3>
                  {intl.formatMessage({ id: "company_details" })}
                </Heading3>
                <InputField
                  onChange={e =>
                    this.onFieldChange(e.target.value, "website_url")
                  }
                  id="organization-website"
                  label={intl.formatMessage({ id: "website" })}
                  value={this.state.website_url}
                />
                <Dropdown
                  label={intl.formatMessage({ id: "company_role_label" })}
                  options={[
                    { value: "itp", label: "IT Professional" },
                    { value: "marketing", label: "Marketing Professional" },
                    { value: "development", label: "Developer/Programmer" },
                    { value: "admin", label: "Site Administrator" }
                  ]}
                  defaultValue={{
                    value: this.state.company_role,
                    label: this.state.company_role
                      ? this.state.company_role
                      : "Select profession"
                  }}
                  onChange={({ value }) => {
                    this.onFieldChange(value, "company_role");
                  }}
                  field={true}
                  className="select-company-role"
                />
                <Dropdown
                  label={intl.formatMessage({ id: "company_type_label" })}
                  options={[
                    {
                      value: "Sells Goods or Services Directly",
                      label: "Sells Goods or Services Directly"
                    },
                    { value: "Builds Websites", label: "Builds Websites" },
                    {
                      value: "Provides Technology Solutions",
                      label:
                        "Provides Technology Solutions (Payment Gateway, Analytics)"
                    },
                    { value: "I work for myself", label: "I work for myself" }
                  ]}
                  defaultValue={{
                    value: this.state.company_type,
                    label: this.state.company_type
                      ? this.state.company_type
                      : "Select activity type"
                  }}
                  onChange={({ value }) =>
                    this.onFieldChange(value, "company_type")
                  }
                  field={true}
                  className="select-company-type"
                />
              </Column>
            </ColumnWrapper>
            <hr />
            <Heading3>{intl.formatMessage({ id: "newsletter" })}</Heading3>
            <CheckboxField
              id="newsletter-label"
              forId="newsletter"
              title={intl.formatMessage({ id: "newsletter" })}
              label={intl.formatMessage({
                id: "newsletter.description"
              })}
              onChange={e => this.onToggleChange(e, "marketing")}
              value={this.state.marketing}
            />
            {this.state.isChanged && (
              <React.Fragment>
                <Button
                  id="save_organization_general_settings_button"
                  className="primary"
                  onClick={() => onSave(this.state)}
                >
                  {intl.formatMessage({ id: "save_changes" })}
                </Button>
                <Button
                  id="cancel-organization-name-btn"
                  onClick={this.onCancel}
                  className="secondary"
                >
                  {intl.formatMessage({ id: "cancel" })}
                </Button>
              </React.Fragment>
            )}
          </ListGroup>
        </ColumnWrapper>
      </OrganizationBasicFormLayout>
    );
  }
}

// Prop keys for the form need to match
// what they are in the user object from the API
// ex: display_name and not displayName. This
// makes it easier to handling saving the form data.
OrganizationBasicFormSettings.propTypes = {
  display_name: PropTypes.string,
  username: PropTypes.string,
  email: PropTypes.string,
  company_role: PropTypes.string,
  company_type: PropTypes.string,
  website_url: PropTypes.string,
  marketing: PropTypes.bool,
  errors: PropTypes.object,
  onSave: PropTypes.func,
  intl: PropTypes.object,
  newsletter: PropTypes.bool
};

export default injectIntl(OrganizationBasicFormSettings);

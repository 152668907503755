import React from "react";
import PropTypes from "prop-types";

const DeleteIcon = ({ color = "#4b6180" }) => (
  <svg
    className="icon-delete"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.0593 21L6.94991 20.9908C6.46938 20.9908 6.06279 20.6518 6.00734 20.2029V11.0135C5.94266 10.5188 6.31229 10.0698 6.83902 10.0057C7.3565 9.95073 7.82778 10.2989 7.89247 10.7936L7.78158 19.1951L16.2092 19.2043L16.1168 10.7936C16.1815 10.2989 16.6527 9.95073 17.1702 10.0149C17.6877 10.079 18.0573 10.5279 17.9927 11.0135V20.2029C17.9372 20.661 17.5306 21 17.0593 21Z"
      fill={color}
    />
    <path
      d="M15.87 5.96V4C15.87 3.45 15.42 3 14.87 3H8.87C8.32 3 7.87 3.45 7.87 4V5.96H4C3.45 5.96 3 6.41 3 6.96C3 7.51 3.45 7.96 4 7.96H20C20.55 7.96 21 7.51 21 6.96C21 6.41 20.55 5.96 20 5.96H15.87ZM9.87 5H13.87V5.96H9.87V5Z"
      fill={color}
    />
  </svg>
);

DeleteIcon.propTypes = {
  color: PropTypes.string
};

export default DeleteIcon;

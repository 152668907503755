import styled from "styled-components";

export default styled.div`
  width: 100%;
  box-sizing: border-box;
  .primary {
    margin-right: 10px;
  }
  h3 {
    margin: 0 0 30px 0;
  }
  textarea {
    width: 100%;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    > div {
      width: 100%;
      box-sizing: boarder-box;
    }
    > div:first-child {
      width: 33%;
      padding-right: 20px;
    }
  }
`;
